@use 'bootstrap' as bs;

body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.search-result-card {
  min-height: 100px;
  font-size: 12px;
  border: 1px solid bs.$gray-100;

  @media (min-width: 768px) {
    min-height: 175px;
  }

  .image-container {
    min-height: 200px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 20px;
  }
}

.ais-HierarchicalMenu-item--selected {
  a,
  a:hover {
    color: inherit !important;
  }
}

.ais-Pagination-item--selected {
  a,
  a:hover {
    color: inherit !important;
  }
}

.ais-RatingMenu-item--selected {
  a,
  a:hover {
    color: inherit !important;
  }
}

// Source: https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/algolia-min.css

// Range slider
.ais-RangeSlider .rheostat {
  overflow: visible;
  margin-top: 40px;
  margin-bottom: 40px;
}

.ais-RangeSlider .rheostat-background {
  height: 6px;
  top: 0;
  width: 100%;
}

.ais-RangeSlider .rheostat-handle {
  margin-left: -12px;
  top: -7px;
}

.ais-RangeSlider .rheostat-background {
  position: relative;
  background-color: #fff;
  border: 1px solid #aaa;
}

.ais-RangeSlider .rheostat-progress {
  position: absolute;
  top: 1px;
  height: 4px;
  background-color: bs.$primary;
}

.rheostat-handle {
  position: relative;
  z-index: 1;
  width: 17px;
  height: 17px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 50%;
  cursor: -webkit-grab;
  cursor: grab;
}

.rheostat-marker {
  margin-left: -1px;
  position: absolute;
  width: 1px;
  height: 5px;
  background-color: #aaa;
}

.rheostat-marker--large {
  height: 9px;
}

.rheostat-value {
  padding-top: 15px;
}

.rheostat-tooltip,
.rheostat-value {
  margin-left: 50%;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.rheostat-tooltip {
  top: -22px;
}

// Rating icon
.ais-RatingMenu-starIcon {
  position: relative;
  top: -1px;
  width: 15px;
  fill: #ffc168;
}

.marker-highlight {
  margin: 0 -0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent
    linear-gradient(
      to right,
      rgba(255, 225, 0, 0.1),
      rgba(255, 225, 0, 0.7) 4%,
      rgba(255, 225, 0, 0.3)
    );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.ais-HitsPerPage-select:focus,
.ais-SortBy-select:focus {
  box-shadow: none;
}
